import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page500({ onClick, buttonLabel }) {
  return (
    <Page title="500 Internal Server Error" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              Ops. Si è verificato un problema
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Qualcosa è andato storto. Ti preghiamo di riprovare.
            </Typography>

            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

            {onClick ? (
              <Button size="large" variant="contained" onClick={onClick}>{buttonLabel}</Button>
            ) : (
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Torna alla home
              </Button>
            )}
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

Page500.propTypes = {
  onClick: PropTypes.func,
  buttonLabel: PropTypes.node,
};
