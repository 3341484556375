import { useAuth } from 'contexts/FirebaseContext';
import { useSearchParams } from 'react-router-dom';

import useReadOnce from './useReadOnce';
import useReadOnceList from './useReadOnceList';

const useTenantId = () => {
  const { user, isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const tenantIdQueryParam = searchParams.get('tenant');

  return isAuthenticated ? user?.tenantId : tenantIdQueryParam;
};

const useTenant = () => {
  const tenantId = useTenantId();
  return useReadOnce(`/tenants/${tenantId}`, { enabled: !!tenantId });
};

export const useTenantPublicData = () => {
  const tenantId = useTenantId();
  const { data, isLoading } = useReadOnceList(
    [
      `/tenants/${tenantId}/logo`,
      `/tenants/${tenantId}/logoSymbol`,
      `/tenants/${tenantId}/slug`,
      `/tenants/${tenantId}/name`,
    ],
    {
      enabled: !!tenantId,
    },
  );

  const [logo, logoSymbol, slug, name] = data || [];

  return {
    isLoading,
    data: data ? { logo, logoSymbol, slug, name } : null,
  };
};

export default useTenant;
