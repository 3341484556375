const paymentMethods = [
  {
    id: 'creditCard',
    label: 'Carta o Bancomat',
    color: '#ba68c8',
  },
  {
    id: 'bankTransfer',
    label: 'Bonifico bancario',
    color: '#fdd835',
  },
  {
    id: 'cash',
    label: 'Contanti',
    color: '#81c784',
  },
  {
    id: 'satispay',
    label: 'Satispay',
    color: '#e57373',
  },
  {
    id: 'paypal',
    label: 'PayPal',
    color: '#7986cb',
  },
  {
    id: 'other',
    label: 'Altro',
    color: '#000000',
  },
];

export default paymentMethods;
