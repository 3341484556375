import moment from 'moment';
import momentTZ from 'moment-timezone';

const DEFAULT_LOCALE = 'it';

// Trick to set the locale and timezone for moment and moment-timezone and resolve the conflict with moment and moment-timezone in the same project
// source: https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
export const setMomentConfig = () => {
  moment.locale(DEFAULT_LOCALE); // apply it to moment
  momentTZ.defineLocale(DEFAULT_LOCALE, moment.localeData()._config); // copy locale to moment-timezone
  momentTZ.locale(DEFAULT_LOCALE); // apply it to moment-timezone
  momentTZ.tz.setDefault(process.env.REACT_APP_DEFAULT_TIMEZONE);
};
