// @mui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  marginTop: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <RootStyle>
      <CircularProgress />
    </RootStyle>
  );
}
