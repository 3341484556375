// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PlanPremiumIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 1546 1296">
        <path
          fill="#32ffd4"
          stroke="#000"
          strokeWidth={4}
          d="M1378.95 1005.62l-50.1-5.79 7.3-43.3c.2-1-.4-1.9-1.3-2.2-.9-.3-1.9.1-2.4.9l-20 35.6-33.3-36.1c-.7-.7-1.8-.9-2.6-.3-.8.6-1.1 1.6-.7 2.5l20.9 43.79-35.5 8c-.9.2-1.6 1-1.6 2s.7 1.8 1.6 1.9l36.1 6.5-14.5 54.8c-.3 1 .2 2 1.2 2.4.2.1.5.2.8.2.7 0 1.4-.4 1.7-1l27.3-47.1 24.5 26.6c.6.7 1.7.9 2.5.4s1.2-1.5.8-2.4l-12.3-34.4 49.6-9.1c1-.2 1.7-1 1.6-2-.1-1-.6-1.8-1.6-1.9z"
        />
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth={4}
          d="M1121.5 230.2c7.9 0 14.3-6.4 14.3-14.3s-6.4-14.3-14.3-14.3-14.3 6.4-14.3 14.3 6.4 14.3 14.3 14.3z"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M1173.35 800.8c29.7 21.5 96.7-3.8 138.1-28.6 46-27.6 48.1-57.3 29.7-57.3-25.6 0-89 78.8-52.2 96.1 30.4 14.4 85.9-11.2 100.2 5.1 14.3 16.3 20.5 52.2 60.3 70.6"
        />
        <path
          fill="#009688"
          stroke="#000"
          strokeWidth={4}
          d="M303.3 1036.3c9.06 0 16.4-7.34 16.4-16.4 0-9.06-7.34-16.4-16.4-16.4-9.06 0-16.4 7.34-16.4 16.4 0 9.06 7.34 16.4 16.4 16.4z"
        />
        <path
          fill="#61ffc3"
          stroke="#000"
          strokeWidth={4}
          d="M1150.7 1118.1c9.88 0 17.9-8.01 17.9-17.9 0-9.89-8.02-17.9-17.9-17.9-9.89 0-17.9 8.01-17.9 17.9 0 9.89 8.01 17.9 17.9 17.9zM162.26 513.5c10.44 0 18.9-8.46 18.9-18.9 0-10.44-8.46-18.9-18.9-18.9-10.44 0-18.9 8.46-18.9 18.9 0 10.44 8.46 18.9 18.9 18.9z"
        />
        <path
          fill="#d7efdf"
          stroke="#000"
          strokeWidth={4}
          d="M248.1 642.93c10.97-7.91 13.46-23.22 5.55-34.19-7.92-10.98-23.23-13.47-34.2-5.55-10.98 7.91-13.46 23.22-5.55 34.2 7.91 10.97 23.22 13.46 34.2 5.54z"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M105.05 854.4c39.5-34.8 112.7-81.8 174-41.9 0 0-60.3-78.8-29.6-115.6 40.1-48.2 158.1 82.8 140.4 105.3-18.2 23-58.9-79.9-37.6-137.1 16.8-45 97 22.5 109.9 62.4"
        />
        <g stroke="#000" strokeWidth={4}>
          <path fill="#d7efdf" d="M1182.78 322.8h-83.9s-9.7 49.6 19.5 75.9h83.9c-29.2-26.3-19.5-75.9-19.5-75.9z" />
          <path fill="#30ff9b" d="M1065.18 279.9h-83.9s-9.7 49.6 19.5 75.9h83.9c-29.3-26.3-19.5-75.9-19.5-75.9z" />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#32ffd4"
            d="M858.25 161.3h86.5c19.9 0 36 16.1 36 36v1.6c0 19.9-16.1 36-36 36h-86.5c-19.9 0-36-16.1-36-36v-1.6c0-19.9 16.1-36 36-36z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M874.05 202.9c5.91 0 10.7-4.79 10.7-10.7 0-5.91-4.79-10.7-10.7-10.7-5.91 0-10.7 4.79-10.7 10.7 0 5.91 4.79 10.7 10.7 10.7zM882.65 198.7l-12.9 16.1M907.35 202.9c5.91 0 10.7-4.79 10.7-10.7 0-5.91-4.79-10.7-10.7-10.7-5.91 0-10.7 4.79-10.7 10.7 0 5.91 4.79 10.7 10.7 10.7zM915.95 198.7l-12.9 16.1M936.35 190.6v15.1M943.85 198.2h-15"
          />
        </g>
        <path
          fill="#61ffc3"
          stroke="#000"
          strokeWidth={4}
          d="M1063.8 527h-138c-7.6 0-13.8-6.2-13.8-13.8v-97.1c0-7.6 6.2-13.8 13.8-13.8h37.3c4.9 0 9.4 2.6 11.9 6.8l4.3 7.3c2.5 4.2 7 6.8 11.9 6.8h72.7c7.6 0 13.8 6.2 13.8 13.8v76.2c0 7.6-6.2 13.8-13.9 13.8.1 0 .1 0 0 0z"
        />
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#61ffc3"
            d="M488.7 757.1h237c17 0 30.9 13.8 30.9 30.9v237c0 17-13.8 30.9-30.9 30.9h-237c-17 0-30.9-13.8-30.9-30.9V788c0-17.1 13.8-30.9 30.9-30.9z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M496.7 998.5h220.9M496.7 998.5V822.6M486.5 901.3c38.9 0 35.8 53.2 63.4 53.2C590.8 954.5 598 841 644 841c46 0 39.9 64.4 71.6 64.4"
          />
          <path
            fill="#fff"
            d="M606.1 889.1c9.06 0 16.4-7.34 16.4-16.4 0-9.06-7.34-16.4-16.4-16.4-9.06 0-16.4 7.34-16.4 16.4 0 9.06 7.34 16.4 16.4 16.4z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M537.6 997.5v-9.2M578.5 997.5v-9.2M619.4 997.5v-9.2M660.3 997.5v-9.2M701.3 997.5v-9.2"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#fff"
            d="M330.72 602.43a33.574 33.574 0 01-23.67-9.83 33.48 33.48 0 01-9.82-23.67V251.47c0-8.88 3.54-17.39 9.82-23.67a33.532 33.532 0 0123.67-9.83H742.6c8.88.01 17.39 3.55 23.67 9.83a33.522 33.522 0 019.82 23.67v317.46a33.522 33.522 0 01-9.82 23.67 33.574 33.574 0 01-23.67 9.83H330.72z"
          />
          <path fill="none" d="M297.25 289.93h480.32" />
          <path
            fill="#fff"
            d="M332.47 265.95c7.03 0 12.74-5.7 12.74-12.73 0-7.04-5.71-12.74-12.74-12.74-7.04 0-12.74 5.7-12.74 12.74 0 7.03 5.7 12.73 12.74 12.73zM371.07 265.95c7.04 0 12.74-5.7 12.74-12.73 0-7.04-5.7-12.74-12.74-12.74-7.03 0-12.74 5.7-12.74 12.74 0 7.03 5.71 12.73 12.74 12.73zM409.67 265.95c7.04 0 12.74-5.7 12.74-12.73 0-7.04-5.7-12.74-12.74-12.74-7.03 0-12.74 5.7-12.74 12.74 0 7.03 5.71 12.73 12.74 12.73z"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path fill="#61ffc3" d="M430.14 387.05h-51.81V548.3h51.81V387.05z" />
          <path fill="#d7efdf" d="M617.56 363.47h-51.81V548.3h51.81V363.47z" />
          <path fill="#009688" d="M523.78 329.41h-51.81V548.3h51.81V329.41zM711.2 455.17h-51.81v93.13h51.81v-93.13z" />
          <path fill="none" strokeLinecap="round" strokeLinejoin="round" d="M352.42 548.3h384.69" />
        </g>
        <path
          fill="#30ff9b"
          stroke="#000"
          strokeWidth={4}
          d="M324.22 381.63l-65.4-14.4 19.8-44.3c.4-.9.1-1.9-.7-2.5-.8-.5-1.9-.4-2.6.2l-34.8 35.2-33.6-40.9c-.6-.8-1.7-1-2.5-.5s-1.2 1.5-.9 2.4l17.7 48.6h-51c-1 0-1.8.7-2 1.7-.2 1 .4 1.9 1.3 2.2l51.4 17.7-19.8 47.6c-.4.9 0 1.9.8 2.5.8.5 1.9.4 2.6-.3l34.7-38.2 43.2 50.3c.4.5 1 .7 1.5.7.4 0 .8-.1 1.1-.3.8-.5 1.1-1.6.7-2.5l-27.2-58 65.4-3.3c1 0 1.8-.8 1.9-1.8.1-.9-.6-1.8-1.6-2.1z"
        />
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#d7efdf"
            d="M993.5 616.9h224.1c11.5 0 20.9 9.4 20.9 20.9v256.9c0 11.5-9.4 20.9-20.9 20.9H993.5c-11.5 0-20.9-9.4-20.9-20.9V637.8c0-11.6 9.4-20.9 20.9-20.9z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M1020.3 682.9l9.2 9.2 14.9-14.8M1038.22 772.62c4.63-3.21 5.78-9.56 2.58-14.19a10.188 10.188 0 00-14.19-2.58c-4.64 3.2-5.79 9.56-2.59 14.19 3.21 4.63 9.57 5.79 14.2 2.58zM1038.21 852.02c4.63-3.21 5.79-9.56 2.58-14.19-3.2-4.63-9.56-5.79-14.19-2.58-4.63 3.2-5.79 9.56-2.58 14.19 3.21 4.63 9.56 5.79 14.19 2.58zM1073.3 686.6h93M1073.3 764.3h93M1073.3 843h93"
          />
        </g>
        <path
          fill="#009688"
          stroke="#000"
          strokeWidth={4}
          d="M1309.2 868.6c6.35 0 11.5-5.15 11.5-11.5s-5.15-11.5-11.5-11.5-11.5 5.15-11.5 11.5 5.15 11.5 11.5 11.5z"
        />
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#009688"
            d="M1326.57 417.39h-52.75c-19.39 0-35.16 15.77-35.16 35.17 0 17.26 12.54 31.93 29.57 34.69v18.05l25.78-17.58h32.56c19.39 0 35.16-15.77 35.16-35.16 0-19.48-15.77-35.17-35.16-35.17z"
          />
          <path
            fill="#fff"
            d="M1280.45 456.81c2.65 0 4.81-2.15 4.81-4.81 0-2.65-2.16-4.81-4.81-4.81-2.66 0-4.81 2.16-4.81 4.81 0 2.66 2.15 4.81 4.81 4.81zM1300.16 456.81c2.65 0 4.81-2.15 4.81-4.81 0-2.65-2.16-4.81-4.81-4.81-2.66 0-4.81 2.16-4.81 4.81 0 2.66 2.15 4.81 4.81 4.81zM1319.95 456.81c2.65 0 4.81-2.15 4.81-4.81 0-2.65-2.16-4.81-4.81-4.81-2.66 0-4.81 2.16-4.81 4.81 0 2.66 2.15 4.81 4.81 4.81z"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#009688"
            d="M408.08 974.99h91.8c13.07 0 23.65 10.58 23.65 23.65v49.26c0 13.07-10.58 23.65-23.65 23.65h-91.8c-13.07 0-23.65-10.58-23.65-23.65v-49.26c0-13.07 10.58-23.65 23.65-23.65z"
          />
          <path
            fill="#009688"
            d="M453.94 1031.2l62.15-49.66c-4.12-4.06-9.86-6.55-16.21-6.55h-91.8c-6.35 0-12.09 2.49-16.24 6.5l62.1 49.71z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M515.82 981.71l-61.88 49.42-61.98-49.52M466.94 1022.14l50.47 40.42M390.17 1062.33l50.63-40.42"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path fill="#32ffd4" d="M478.3 797c36.45 0 66-29.55 66-66s-29.55-66-66-66-66 29.55-66 66 29.55 66 66 66z" />
          <path
            fill="#fff"
            d="M496.3 749c16.9-16.9 22.5-38.7 12.5-48.7-1.5-1.5-3.3-2.7-5.2-3.5-1.7-.7-3.5-1.1-5.3-1.3-1.5-.2-3 .6-3.6 1.9l-8 15.5c-.9 1.8-.2 3.9 1.5 4.8.1.1.2.1.3.1l4.4 1.8c1.8.7 2.6 2.7 1.9 4.4-.1.2-.2.4-.3.5-5.7 9.1-13.4 16.8-22.5 22.6-1.6 1-3.7.5-4.7-1.1-.1-.2-.2-.4-.3-.5l-1.8-4.5c-.7-1.8-2.8-2.7-4.6-2l-.3.1-15.5 8c-1.3.6-2.1 2-1.9 3.5.2 1.9.6 3.7 1.3 5.5.8 2 2 3.7 3.5 5.2 9.9 10.2 31.7 4.6 48.6-12.3z"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path
            fill="#ffbb94"
            d="M771.9 314.05c-16.8-9.3-33-13.3-43.8-9-25 8.9-36.3 51-36.7 52.7 0 0-9.2-.1-10.5 3.6-1.5 4.6 5.2 11.4 5.2 11.4-3.6 10.4-7.6 24.5-1.7 36.7 5.6 11.5 20.3 17.5 38.7 14.5l-.6 6.8 71.6 10.8c.9-3 6.2-57.2 7.2-67.5 2-21.8-2.5-45.2-29.4-60zM967.6 1107.05l72.5-32.8-3.81-20.23c-.69-4.17-1.79-8.17-2.89-11.97-.8-2.7-1.6-5.5-2.5-8.3-3.4-11.2-7.5-23.7-12.5-37.5-5.1-14.2-10.4-27-15.8-38.8l-98.1 34.6c13.5 20.5 26.5 41.4 34.6 57.9 2.2 4.2 4 8.8 5.6 13.3l22.9 43.8z"
          />
          <path
            fill="#d7efdf"
            d="M1006.5 1066.75c-13.2 5.6-17.2 27.4-25.6 28.7-8.3 1.3-.6-19.7-5.8-32-4.6-10.8-15.2-6.9-30.7-.3 1 2.8 1.8 5.6 2.5 8.4 1.5 6.5 1.7 12.1.2 16.5-7.9 23-28.6 35.1-29.1 57l.1.6 127-54v-.1c-2.4-12.5-5.3-25.3-8.7-37.8l-29.9 13z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M962.9 1089.35l-15.27-1.43M958.9 1105.55l-16.99-5.37"
          />
          <path
            fill="#ffbb94"
            d="M678.6 409.05c-8.3-3.7-16.2 10.7-23.5 18.4-3.4 3.6-7.5 5.4-9.2 2-7.2-14.3 26.6-18.4 29.7-24.5 3.1-6.1-2-10.2-14.3-8.2-18.4-5.1-35.5 3.2-43 15.3-8.2 13.3-7.5 17.5-23.5 46-2.9 5.2-5.9 10.8-8.7 16.4l47.3 29.9 25.9-44.2c-.1 0 28.5-47 19.3-51.1z"
          />
          <path
            fill="#30ff9b"
            d="M842.2 484.75c-13.3-24-35.8-37.9-48.1-43 .3-.9.9-5.8 1.6-12.6l-73.2.3-1.1 11.1s-19.3.4-32.7 4.1c-11.2 3.1-19.4 6.3-29.7 15.5l-30.7 36.7-41.8-23.7c-20.2 40.2-35.7 89.8-6.3 113.7 38.9 31.7 99.2-26.5 99.2-26.5l20.7 67.6 112.6 1.8 20.2 16.3s16-1.5 25.4-7c44.1-25.2-.7-126.7-16.1-154.3z"
          />
          <path
            fill="#221f1f"
            d="M888.2 483.65c-8.2-63.4-72.6-61.4-81.8-108.4-5.5-28.1-4.8-50.5-31.7-65.4-16.8-9.3-35.8-9.1-46.6-4.8-13.5 4.8-22.7 18-28 29.4l14.6 12.9s23.63-13.09 23.2-12.1c-10.2 13.3-15 20-15 20l15.8 15.2s30.6-16.4 36.9 3.2c6.3 19.6-24.5 33.3-24.5 33.3-6.1 57.2 19.5 76.8 19.5 76.8h22.9s-7.16-30.27-5.5-28.6c9.2 12.3 25.1 28.6 25.1 28.6l75.1-.1z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M812.6 627.85l14.2-85.9M819.7 593.05l14.3 6.1M680.2 559.65l21.9-18.2M634.6 407.95c7.08-5.39 17.33-9.55 26.54-11.14M695.8 381.85l8.4-.4M723.8 423.85c4.1-1.5 8.1-3 12.1-4.5M722.5 440.65h9.1M748 386.25l9-4.4"
          />
          <path
            fill="#221f1f"
            d="M706.7 364.35c1.77 0 3.2-1.43 3.2-3.2 0-1.77-1.43-3.2-3.2-3.2-1.77 0-3.2 1.43-3.2 3.2 0 1.77 1.43 3.2 3.2 3.2zM803.9 325.15c15.52 0 28.1-12.58 28.1-28.1 0-15.52-12.58-28.1-28.1-28.1-15.52 0-28.1 12.58-28.1 28.1 0 15.52 12.58 28.1 28.1 28.1z"
          />
          <path fill="none" strokeLinecap="round" strokeLinejoin="round" d="M751.1 285.25l67.6 62.4" />
          <path
            fill="#ffbb94"
            d="M813.1 1090.55c1.4-12.6 2.6-27.1 3.5-43.4.8-13.8 1-26.8.6-38.8l-102.2-.1c4.3 24 7.5 46.5 7.7 64 0 3.5 0 6.7-.2 9.9l7.5 42.2 16.2 12.6 58.2-6.6 6.2-20.7c.6-4.3 1.2-8.6 1.7-12.9.4-2.1.7-4.1.8-6.2z"
          />
          <path
            fill="#d7efdf"
            d="M744.9 1125.95c-8.2-2 7.2-18.4 7.2-31.7 0-12.1-11.8-12.3-29.5-12.3h-.1c-.4 5.7-1.3 10.7-2.7 14.7-.9 2.7-2.4 5.3-4.3 7.4-16.4 18.1-40 21.8-49.1 40.9l.1 4.2 136.4-1.9 1.5-2.3c2.6-11.8 4.7-23.7 6.3-35.5h-31c-14.2.1-26.5 18.6-34.8 16.5z"
          />
          <path
            fill="#009688"
            d="M936.3 847.75c-28.7-40.4-35.5-91.3-60.2-141.6-24.7-50.3-63.6-78.3-63.6-78.3h-34.4c0-.1-.1-.1-.1-.1h-77.9s-18.9 123.6-2.8 275.3c3.4 32.6 11.5 72.7 17.7 107.4l119.7.6c9.2-32.9-27.3-75.9-36.3-114.2-5.8-25-1.9-55.2 2.9-86.3 15.6 32.4 32.9 66.4 51.1 99.4 14.7 26.5 34.9 56 53 83.6l115.9-42.5c-9.3-53.2-70.4-82.8-85-103.3z"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M728.2 1112.05l-13.27-7.31M717.7 1124.35l-12.27-10.51M849.4 665.45h-18.3l5.5 20.4c2.8 10.3 14 18.6 22.2 18.6 6.8 0 10.7-2.1 13.1-5.5M801.2 810.55c3.4-28.5 11.7-56.4 13.4-84.1M776.7 704.55c10.2 0 16.3-8.3 13.6-18.5l-5.5-20.3h-37l5.5 20.3c2.8 10.2 13.3 18.5 23.4 18.5zM720.8 628.95c-5.7 44-7.7 160.2 3.8 228 11.2 66.5 27.6 100.2 31.1 150.8M598 538.35l29.6-41"
          />
          <path
            fill="#009688"
            d="M1047.3 1106.55l-126.8 53.5c-1.8-4.8-2.7-10.2-2.6-15.3l127.1-53.6c1.9 9.9 2.3 15.4 2.3 15.4zM800.5 1160.05H662.9c.3-5.2 1.5-10.3 3.6-15h138c-2.3 10.1-4 15-4 15z"
          />
        </g>
        <g stroke="#000" strokeWidth={4}>
          <path fill="#fff" d="M281.75 893.86l-17.3 25.91 31.51-12.86-14.21-13.05z" />
          <path fill="#fff" d="M281.75 893.86l18.26 40.32 59.67-70.93c.13-.13 0-.32-.19-.25l-77.74 30.86z" />
          <path fill="#fff" d="M246.51 868.65l35.24 25.21 77.8-30.86c.07-.07.07-.2-.06-.2l-112.98 5.85z" />
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M281.75 893.86l7.65 16.91M359.49 863l-77.74 30.86"
          />
        </g>
      </svg>
    </Box>
  );
}
