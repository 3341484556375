import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stack, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

import { IconButtonAnimate } from 'components/animate';
import analytics from 'utils/analytics';
import useIsMountedRef from 'hooks/useIsMountedRef';
import MenuPopover from 'components/MenuPopover';
import { useAuth } from 'contexts/FirebaseContext';
import MyAvatar from '../../../components/MyAvatar';
import { PATH_AUTH } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create(['opacity', 'transform'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, logout } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (event) => {
    if (isMenuOpen) setMenuOpen(null);
    else {
      setMenuOpen(event.currentTarget);
    }
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      queryClient.removeQueries({ active: true });
      queryClient.clear();
      navigate(PATH_AUTH.login, { replace: true });
      analytics.logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Stack direction="row" alignItems="center">
          <IconButtonAnimate
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(isMenuOpen && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <MyAvatar />
          </IconButtonAnimate>

          <Box
            sx={{
              ml: 2,
              width: '150px',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {user?.displayName}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {user?.email}
            </Typography>
          </Box>
        </Stack>
      </RootStyle>
      <MenuPopover
        open={Boolean(isMenuOpen)}
        anchorEl={isMenuOpen}
        onClose={handleClose}
        arrow="top-left"
        sx={{
          p: 0,
          mt: 1.5,
          // ml: 2.2,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
