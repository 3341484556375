import { generatePath } from 'react-router';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportIcon from '@mui/icons-material/Support';

// routes
import { PATH_APPLICATION } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  patients: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    hideSubheader: true,
    items: [
      { title: 'dashboard', path: PATH_APPLICATION.dashboard, icon: ICONS.dashboard },
      { title: 'pazienti', path: PATH_APPLICATION.patients.root, icon: ICONS.patients },
      {
        title: 'calendario',
        path: PATH_APPLICATION.calendar.root,
        icon: ICONS.calendar,
      },
      {
        title: 'impostazioni',
        path: generatePath(PATH_APPLICATION.user.settings, { tab: '' }),
        icon: <SettingsIcon />,
      },
      { title: 'FAQ', path: PATH_APPLICATION.faqs, icon: <HelpOutlineIcon /> },
      { title: 'Supporto', path: 'dd', icon: <WhatsAppIcon /> },
    ],
  },
];

export const getNavConfig = () => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    hideSubheader: true,
    items: [
      { title: 'dashboard', path: PATH_APPLICATION.dashboard, icon: ICONS.dashboard },
      { title: 'pazienti', path: PATH_APPLICATION.patients.root, icon: ICONS.patients },
      {
        title: 'calendario',
        path: PATH_APPLICATION.calendar.root,
        icon: ICONS.calendar,
      },
      {
        title: 'impostazioni',
        path: generatePath(PATH_APPLICATION.user.settings, { tab: '' }),
        icon: <SettingsIcon />,
      },
      {
        title: 'Aiuto',
        icon: <SupportIcon />,
        children: [
          {
            title: 'Chat di supporto',
            path: 'https://wa.me/+393759148925',
            icon: <WhatsAppIcon />,
          },
          {
            title: 'FAQ',
            path: PATH_APPLICATION.faqs,
            icon: <HelpOutlineIcon />,
          },
        ],
      },
    ],
  },
];

export default navConfig;
