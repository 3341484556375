import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import {
  USER_SUBSCRIPTIONS,
  UNLIMITED_FEATURES,
  PREMIUM_FEATURES,
  FREE_FEATURES,
} from 'constants/userSubscriptionsTypes';

// ----------------------------------------------------------------------

export const UNLIMITED__FULL_FEATURES = [...UNLIMITED_FEATURES, ...PREMIUM_FEATURES, ...FREE_FEATURES];

export default function UnlimitedSubscriptionQuickDetails() {
  return (
    <>
      <Typography variant="h3" color="secondary">
        {USER_SUBSCRIPTIONS.unlimited.title}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 1 }}>
          <Typography variant="h3" sx={{ color: 'text.secondary' }}>
            €
          </Typography>
          <Typography variant="h2" sx={{ mx: 1 }}>
            {USER_SUBSCRIPTIONS.unlimited.price}{' '}
            <Typography component="span" variant="body1">
              + iva
            </Typography>
          </Typography>

          <Typography
            gutterBottom
            component="span"
            variant="subtitle1"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary',
            }}
          >
            /mese
          </Typography>
        </Box>
      </Box>

      <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <AddIcon color="primary" />
          <Typography variant="body1">Tutte le funzionalità del piano Premium</Typography>
        </Stack>
        {UNLIMITED_FEATURES.map((feature, i) => (
          <Stack key={i} direction="row" alignItems="flex-start" spacing={1}>
            <CheckCircleIcon color="primary" />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: feature.replace('Appuntamenti illimitati', '<strong>Appuntamenti illimitati</strong>'),
              }}
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
}

UnlimitedSubscriptionQuickDetails.propTypes = {};
