import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { generatePath, useNavigate } from 'react-router-dom';

import { PATH_APPLICATION } from 'routes/paths';
import logger from 'utils/logger';
import { USER_SUBSCRIPTIONS, userSubscriptionsTypes } from 'constants/userSubscriptionsTypes';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useAuth } from 'contexts/FirebaseContext';
import usePurchaseSubscriptionMutation from '../hooks/usePurchaseSubscriptionMutation';

export default function PurchaseSubscriptionButton({ subscriptionType, fullWidth }) {
  const { user } = useAuth();
  const purchaseSubscriptionMutation = usePurchaseSubscriptionMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const isTrialActive = Boolean(user.subscription?.trial?.active);

  const currentSubscription = USER_SUBSCRIPTIONS[user.subscription?.type] || USER_SUBSCRIPTIONS.free;

  const targetSubscription = USER_SUBSCRIPTIONS[subscriptionType];
  const isTargetSubscription = currentSubscription.id === targetSubscription.id;


  const handlePurchaseSubscription = async () => {
    try {
      const checkoutSession = await purchaseSubscriptionMutation.mutateAsync({
        subscriptionType,
      });

      if (checkoutSession && checkoutSession.paymentLink) {
        window.location.href = checkoutSession.paymentLink;
      }

      if (checkoutSession && checkoutSession.subscriptionUpdated) {
        navigate(generatePath(PATH_APPLICATION.user.subscription.thankYou, { subscriptionType }));
      }
    } catch (error) {
      enqueueSnackbar('Si è verificato un errore! Riprova.', {
        variant: 'error',
      });
      logger.captureException(error);
    }
  };

  const getDialogDescription = () => {
    if (isTrialActive) {
      return (
        <>
          <Typography variant="body1">
            Il tuo periodo di prova verrà interrotto immediatamente e il costo del nuovo piano verrà addebitato subito.
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="body1">
          L'aggiornamento del piano è immediato. Il costo della tua sottoscrizione verrà calcolato in modo proporzionale
          per il mese corrente. Eventuali costi ti verranno addebitati subito.
        </Typography>
      </>
    );
  };


  if (isTargetSubscription && !isTrialActive) {
    return null;
  }

  return (
    <Box>
      <LoadingButton
        variant="contained"
        size="large"
        fullWidth={fullWidth}
        loading={purchaseSubscriptionMutation.isLoading}
        onClick={() => setIsConfirmationDialogOpen(true)}
      >
        {isTargetSubscription || isTrialActive ? `ATTIVA ORA` : `PASSA AL PIANO ${targetSubscription.plainTextTitle.toUpperCase()}`}
      </LoadingButton>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={`Sei sicuro di voler passare al piano ${targetSubscription.plainTextTitle}?`}
        description={getDialogDescription()}
        confirmLabel={`Sì, passa a ${targetSubscription.plainTextTitle}`}
        discardLabel="Annulla"
        onConfirm={handlePurchaseSubscription}
        isSubmitting={purchaseSubscriptionMutation.isLoading}
        onDiscard={() => setIsConfirmationDialogOpen(false)}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
    </Box>
  );
}

PurchaseSubscriptionButton.propTypes = {
  subscriptionType: PropTypes.oneOf(Object.values(userSubscriptionsTypes)).isRequired,
  fullWidth: PropTypes.bool,
};
