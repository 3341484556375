import { useAuth } from 'contexts/FirebaseContext';
import httpClient from 'utils/fetchers';

const useCloudFunctionHttpClient = () => {
  const { user } = useAuth();

  const getIdToken = async () => {
    if (user && typeof user.getIdToken === 'function') {
      return user.getIdToken();
    }
    return null;
  };

  const getMethod = async (path) => {
    const idToken = await getIdToken();

    return httpClient.get(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/${path}`, {
      Authorization: idToken ? `Bearer ${idToken}` : undefined,
    });
  };

  const postMethod = async (path, payload) => {
    const idToken = await getIdToken();

    return httpClient.post(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/${path}`, payload, {
      Authorization: idToken ? `Bearer ${idToken}` : undefined,
    });
  };

  const patchMethod = async (path, payload) => {
     const idToken = await getIdToken();

    return httpClient.patch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/${path}`, payload, {
      Authorization: idToken ? `Bearer ${idToken}` : undefined,
    });
  };

  const deleteMethod = async (path) => {
     const idToken = await getIdToken();

    return httpClient.delete(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/${path}`, {
      Authorization: idToken ? `Bearer ${idToken}` : undefined,
    });
  };

  return {
    get: getMethod,
    delete: deleteMethod,
    post: postMethod,
    patch: patchMethod,
  };
};

export default useCloudFunctionHttpClient;
