import React from 'react';
import { Typography, Box, Grid, Link, styled, useMediaQuery, useTheme } from '@mui/material';

import { NavLink as RouterLink, generatePath } from 'react-router-dom';
import { PATH_APPLICATION } from 'routes/paths';

const FeatureImage = styled('img')(() => ({
  borderRadius: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
}));

const FEATURES_PREVIEW_LIST = [
  {
    id: 'calendar',
    title: 'Calendario degli appuntamenti integrato',
    description: (
      <>
        Il calendario integrato ti permette di aggiungere i tuoi appuntamenti in modo comodo e veloce, seleziondo il
        paziente dalla lista o creandone uno al volo.{' '}
        <Link noWrap component={RouterLink} to={generatePath(PATH_APPLICATION.calendar.root)} underline="none">
          Provalo ora
        </Link>
      </>
    ),
    image: '/images/calendar.gif',
  },
  {
    id: 'whatsapp',
    title: 'Conferma e promemoria appuntamento automatici',
    description: (
      <>
        Per ogni appuntamento che crei sul Calendario, il cliente riceverà un messaggio, via WhatsApp o SMS, di conferma
        e un messaggio di promemoria automatici. Attiva subito questa funzionalità dalle{' '}
        <Link
          noWrap
          component={RouterLink}
          to={generatePath(PATH_APPLICATION.user.settings, {
            tab: 'calendar',
          })}
          underline="none"
        >
          Impostazioni del calendario
        </Link>
      </>
    ),
    image: '/images/whatsapp_settings.jpg',
  },
  {
    id: 'treatmentRevenues',
    title: 'Importo incassato e metodo di pagamento per ogni trattamento',
    description: "Per ogni trattamento puoi aggiungere l'importo incassato e il metodo di pagamento utilizzato.",
    image: '/images/revenuesInput.gif',
  },
  {
    id: 'dashboardRevenues',
    title: 'Analizza i tuoi incassi nel tempo',
    description:
      'Comincia ad aggiungere i tuoi incassi per scoprire il nuovo widget nella Dashboard: scopri quanto hai fatturato questo mese e in quali mesi fatturi di più e tieni sotto controllo i metodi di pagamento più utilizzati dai tuoi clienti.',
    image: '/images/revenuesReport.png',
  },
];

export default function PremiumFeaturesDetailedList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container rowSpacing={{ xs: 4, md: 16 }} columnSpacing={4}>
      {FEATURES_PREVIEW_LIST.map((feature, index) => {
        if (index % 2 === 0 || isMobile) {
          return (
            <React.Fragment key={feature.id}>
              <Grid item xs={12} md={6}>
                <FeatureImage alt={feature.id} src={feature.image} width="100%" height="auto" />
              </Grid>
              <Grid item xs={12} md={6} alignSelf="center">
                <Box sx={{ textAlign: 'left', pl: isMobile ? 0 : 6, mb: isMobile ? 4 : 0 }}>
                  <Typography variant="h5" color="text.primary" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1">{feature.description}</Typography>
                </Box>
              </Grid>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={feature.id}>
            <Grid item xs={12} md={6} alignSelf="center">
              <Box sx={{ textAlign: 'left', pr: 6 }}>
                <Typography variant="h5" color="text.primary" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body1">{feature.description}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FeatureImage alt="revenues" src={feature.image} width="100%" height="auto" />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}
