import amplitude from 'amplitude-js';

const init = () => {
  if (process.env.REACT_APP_ANALYTICS_ENABLED) {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    amplitude.getInstance().setVersionName(process.env.REACT_APP_VERSION);
  }
};

const setUserId = (userId) => {
  if (process.env.REACT_APP_ANALYTICS_ENABLED) {
    amplitude.getInstance().setUserId(userId);
  }
};

const logEvent = (...args) => {
  if (process.env.REACT_APP_ANALYTICS_ENABLED) {
    amplitude.getInstance().logEvent(...args);
  }
};

const logout = () => {
  if (process.env.REACT_APP_ANALYTICS_ENABLED) {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }
};

export default {
  init,
  setUserId,
  logEvent,
  logout,
};
