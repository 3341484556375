import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import SeverErrorIllustration from '../assets/illustration_500';

const CorruptedAccountScreen = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
        textAlign: 'center',
        px: 2,
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 480, mb: 3 }}>
        <SeverErrorIllustration />
      </Box>
      <Typography variant="h4" gutterBottom>
        Si è verificato un errore con il tuo account
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Contatta l'assistenza tramite la chat WhatsApp o alla mail{' '}
        <Link href="mailto:support@pazientiapp.it">support@pazientiapp.it</Link>
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Ci scusiamo per il disagio
      </Typography>
      <Button
        startIcon={<WhatsAppIcon />}
        variant="contained"
        color="primary"
        href="https://wa.me/+393759148925" // Replace with your actual WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
      >
        Contatta assistenza su WhatsApp
      </Button>
    </Box>
  );
};

export default CorruptedAccountScreen;
