import React from 'react';
import { Container, Typography, Box, Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useSettings } from 'contexts/SettingsContext';
import Page from 'components/Page';
import { USER_SUBSCRIPTIONS, userSubscriptionsTypes } from 'constants/userSubscriptionsTypes';
import { useAnchorLink } from 'hooks/useAnchorLink';
import PremiumFeaturesDetailedList from './components/PremiumSubscriptionFeaturesDetailedList';
import PremiumSubscriptionQuickDetails from './components/PremiumSubscriptionQuickDetails';
import UnlimitedSubscriptionQuickDetails from './components/UnlimitedSubscriptionQuickDetails';
import PurchaseSubscriptionButton from './components/PurchaseSubscriptionButton';

export default function SubscriptionDetails() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { subscriptionType } = useParams();

  useAnchorLink();

  const currentSubscription = USER_SUBSCRIPTIONS[subscriptionType];

  return (
    <Page title={`Piano ${currentSubscription.plainTextTitle}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container spacing={{ xs: 4, md: 6 }}>
          {!isMobile && (
            <Grid item xs={12} md={6}>
              <Box sx={{ width: '90%' }}>{currentSubscription.illustration}</Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              {currentSubscription.id === userSubscriptionsTypes.PREMIUM ? (
                <>
                  <PremiumSubscriptionQuickDetails showFullFeatures />
                  <PurchaseSubscriptionButton subscriptionType={subscriptionType} fullWidth />
                </>
              ) : null}
              {currentSubscription.id === userSubscriptionsTypes.UNLIMITED ? (
                <>
                  <UnlimitedSubscriptionQuickDetails showFullFeatures />
                  <PurchaseSubscriptionButton subscriptionType={subscriptionType} fullWidth />
                </>
              ) : null}
            </Card>
          </Grid>
        </Grid>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Box sx={{ textAlign: 'center', mt: 4, pb: 8, marginTop: 8 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }} id="features">
              Dai uno sguardo a tutte le funzionalità
            </Typography>
            <ArrowDownwardIcon color="primary" fontSize="large" />
          </Box>

          <PremiumFeaturesDetailedList />
        </Container>
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <PurchaseSubscriptionButton subscriptionType={subscriptionType} />
        </Box>
      </Container>
    </Page>
  );
}
