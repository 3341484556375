import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// routes
import Router from 'routes';

// components
import TawktoWidget from 'components/TawktoWidget';
import RtlLayout from 'components/RtlLayout';
import { ChartStyle } from 'components/chart';
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import NotistackProvider from 'components/NotistackProvider';
import ThemeColorPresets from 'components/ThemeColorPresets';
import ThemeLocalization from 'components/ThemeLocalization';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import MaintenanceModeGuard from 'guards/MaintenanceModeGuard';
import PendoWidget from 'components/PendoWidget';
// import analytics from './utils/analytics';

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();

  useEffect(() => {
    // Temporary disabled, too much noise
    // analytics.logEvent('PAGE_VIEW', { path: location.pathname });
  }, [location]);

  return (
    <ThemeColorPresets>
      <ThemeLocalization>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <MaintenanceModeGuard>
                <Router />
              </MaintenanceModeGuard>
              <PendoWidget />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeLocalization>
    </ThemeColorPresets>
  );
}
