import { useMutation } from 'react-query';
import useCloudFunctionHttpClient from 'hooks/useCloudFunctionHttpClient';

const usePurchaseSubscriptionMutation = (options) => {
  const httpClient = useCloudFunctionHttpClient();

  return useMutation((payload) => httpClient.post(`users/purchaseSubscription`, payload), options);
};

export default usePurchaseSubscriptionMutation;
