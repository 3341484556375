import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useAuth } from 'contexts/FirebaseContext';

const PendoWidget = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_PENDO_ENABLED && window && window.pendo) {
      window.pendo.initialize();
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_PENDO_ENABLED && window && window.pendo) {
      if (user && user.id) {
        window.pendo.updateOptions({
          visitor: {
            id: user.id,
            firstName: user.name,
            lastName: user.surname,
            email: user.email,
            creationDate: moment(user.creationDate).toISOString(),
            subscription: user.subscription?.type,
          },
        });
      } else {
        window.pendo.clearSession?.();
      }
    }
  }, [user]);

  return null;
};

export default PendoWidget;
