// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PlanPremiumIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 1546 1296">
        <g>
          <path
            d="M1182.78 322.8H1098.88C1098.88 322.8 1089.18 372.4 1118.38 398.7H1202.28C1173.08 372.4 1182.78 322.8 1182.78 322.8Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M1065.18 279.9H981.28C981.28 279.9 971.58 329.5 1000.78 355.8H1084.68C1055.38 329.5 1065.18 279.9 1065.18 279.9Z"
            fill="#30ff9b"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
        </g>
        <g>
          <path
            d="M1063.8 527H925.8C918.2 527 912 520.8 912 513.2V416.1C912 408.5 918.2 402.3 925.8 402.3H963.1C968 402.3 972.5 404.9 975 409.1L979.3 416.4C981.8 420.6 986.3 423.2 991.2 423.2H1063.9C1071.5 423.2 1077.7 429.4 1077.7 437V513.2C1077.7 520.8 1071.5 527 1063.8 527 1063.9 527 1063.9 527 1063.8 527Z"
            fill="#61ffc3"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
        </g>
        <g>
          <path
            d="M487.42 767H749.39C760.05 767 768.62 775.63 768.62 786.28V985.95C768.62 996.61 759.99 1005.18 749.39 1005.18H487.42C476.76 1005.18 468.13 996.55 468.13 985.95V786.28C468.19 775.63 476.76 767 487.42 767Z"
            fill="#00000000"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M477.9 757.48H739.88C750.53 757.48 759.1 766.11 759.1 776.77V976.44C759.1 987.1 750.47 995.66 739.88 995.66H477.9C467.24 995.66 458.61 987.03 458.61 976.44V776.77C458.68 766.11 467.24 757.48 477.9 757.48Z"
            fill="#61ffc3"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M501.25 782.16H584.05C588.93 782.16 592.93 786.16 592.93 791.04V791.42C592.93 796.31 588.93 800.31 584.05 800.31H501.25C496.36 800.31 492.37 796.31 492.37 791.42V791.04C492.37 786.16 496.36 782.16 501.25 782.16Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M705.68 782.16H759.1V800.31H705.68C700.79 800.31 696.8 796.31 696.8 791.42V791.04C696.8 786.16 700.79 782.16 705.68 782.16Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M570.73 866.86H598.39C603.27 866.86 607.27 870.86 607.27 875.75V876.13C607.27 881.01 603.27 885.01 598.39 885.01H570.73C565.84 885.01 561.84 881.01 561.84 876.13V875.75C561.84 870.8 565.84 866.86 570.73 866.86Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M543.44 951.57H571.11C575.99 951.57 579.99 955.56 579.99 960.45V960.83C579.99 965.71 575.99 969.71 571.11 969.71H543.44C538.56 969.71 534.56 965.71 534.56 960.83V960.45C534.56 955.5 538.56 951.57 543.44 951.57Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M603.84 951.57H657.46C662.34 951.57 666.34 955.56 666.34 960.45V960.83C666.34 965.71 662.34 969.71 657.46 969.71H603.84C598.96 969.71 594.96 965.71 594.96 960.83V960.45C594.96 955.5 598.9 951.57 603.84 951.57Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M570.73 909.18H665.2C670.08 909.18 674.08 913.18 674.08 918.07V918.45C674.08 923.33 670.08 927.33 665.2 927.33H570.73C565.84 927.33 561.84 923.33 561.84 918.45V918.07C561.84 913.18 565.84 909.18 570.73 909.18Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M501.25 824.48H584.05C588.93 824.48 592.93 828.48 592.93 833.36V833.74C592.93 838.63 588.93 842.63 584.05 842.63H501.25C496.36 842.63 492.37 838.63 492.37 833.74V833.36C492.37 828.48 496.36 824.48 501.25 824.48Z"
            fill="#76ddaf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M627.83 824.35H669.77C674.65 824.35 678.65 828.35 678.65 833.24V833.62C678.65 838.5 674.65 842.5 669.77 842.5H627.83C622.94 842.5 618.95 838.5 618.95 833.62V833.24C618.95 828.29 622.94 824.35 627.83 824.35Z"
            fill="#76ddaf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M477.9 757.48H513.81V995.66H477.9C467.24 995.66 458.61 987.03 458.61 976.44V776.77C458.61 766.11 467.24 757.48 477.9 757.48Z"
            fill="#61ffc3"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M477.9 757.48H513.81V995.66H477.9C467.24 995.66 458.61 987.03 458.61 976.44V776.77C458.61 766.11 467.24 757.48 477.9 757.48Z"
            fill="#07ffa1"
            opacity={0.2}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M759.1 812.04H514.45"
            stroke="#000000"
            strokeWidth={4}
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M759.1 854.87H514.45"
            stroke="#000000"
            strokeWidth={4}
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M759.1 898.33H514.45"
            stroke="#000000"
            strokeWidth={4}
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M759.1 939.19H514.45"
            stroke="#000000"
            strokeWidth={4}
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M513.81 757.48V995.66"
            stroke="#000000"
            strokeWidth={4}
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M480.06 791.23H492.37"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M480.06 833.43H492.37"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M480.06 875.62H492.37"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M480.06 918.45H492.37"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M480.06 960.64H492.37"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
        <g>
          <path
            d="M330.72 602.43C321.84 602.41 313.33 598.88 307.05 592.6 300.77 586.32 297.23 577.81 297.23 568.93V251.47C297.23 242.59 300.77 234.08 307.05 227.8 313.33 221.52 321.84 217.98 330.72 217.97H742.6C751.48 217.98 759.99 221.52 766.27 227.8 772.55 234.08 776.08 242.59 776.09 251.47V568.93C776.08 577.81 772.55 586.32 766.27 592.6 759.99 598.88 751.48 602.41 742.6 602.43H330.72Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path d="M297.25 289.93H777.57" stroke="#000000" strokeWidth={4} opacity={1} fill="none" />
          <path
            d="M332.47 265.95C339.5 265.95 345.21 260.25 345.21 253.22 345.21 246.18 339.5 240.48 332.47 240.48 325.43 240.48 319.73 246.18 319.73 253.22 319.73 260.25 325.43 265.95 332.47 265.95Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M371.07 265.95C378.11 265.95 383.81 260.25 383.81 253.22 383.81 246.18 378.11 240.48 371.07 240.48 364.04 240.48 358.33 246.18 358.33 253.22 358.33 260.25 364.04 265.95 371.07 265.95Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M409.67 265.95C416.71 265.95 422.41 260.25 422.41 253.22 422.41 246.18 416.71 240.48 409.67 240.48 402.64 240.48 396.93 246.18 396.93 253.22 396.93 260.25 402.64 265.95 409.67 265.95Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
        </g>
        <g>
          <path
            d="M430.14 387.05H378.33V548.3H430.14V387.05Z"
            fill="#61ffc3"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M617.56 363.47H565.75V548.3H617.56V363.47Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M523.78 329.41H471.97V548.3H523.78V329.41Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M711.2 455.17H659.39V548.3H711.2V455.17Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M352.42 548.3H737.11"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
        <g>
          <path
            d="M993.5 616.9H1217.6C1229.1 616.9 1238.5 626.3 1238.5 637.8V894.7C1238.5 906.2 1229.1 915.6 1217.6 915.6H993.5C982 915.6 972.6 906.2 972.6 894.7V637.8C972.6 626.2 982 616.9 993.5 616.9Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M1020.3 682.9L1029.5 692.1 1044.4 677.3"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M1038.22 772.62C1042.85 769.41 1044 763.06 1040.8 758.43 1037.59 753.79 1031.24 752.64 1026.61 755.85 1021.97 759.05 1020.82 765.41 1024.02 770.04 1027.23 774.67 1033.59 775.83 1038.22 772.62Z"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M1038.21 852.02C1042.84 848.81 1044 842.46 1040.79 837.83 1037.59 833.2 1031.23 832.04 1026.6 835.25 1021.97 838.45 1020.81 844.81 1024.02 849.44 1027.23 854.07 1033.58 855.23 1038.21 852.02Z"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M1073.3 686.6H1166.3"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M1073.3 764.3H1166.3"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M1073.3 843H1166.3"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
        <g>
          <path
            d="M416.24 989.8H508.04C521.11 989.8 531.69 1000.38 531.69 1013.45V1062.71C531.69 1075.78 521.11 1086.36 508.04 1086.36H416.24C403.17 1086.36 392.59 1075.78 392.59 1062.71V1013.45C392.59 1000.38 403.17 989.8 416.24 989.8Z"
            fill="#00968800"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M408.08 974.99H499.88C512.95 974.99 523.53 985.57 523.53 998.64V1047.9C523.53 1060.97 512.95 1071.55 499.88 1071.55H408.08C395.01 1071.55 384.43 1060.97 384.43 1047.9V998.64C384.43 985.57 395.01 974.99 408.08 974.99Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M453.94 1044.95L516.13 981.49H391.76L453.94 1044.95Z"
            fill="#00968800"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M453.94 1031.2L516.09 981.54C511.97 977.48 506.23 974.99 499.88 974.99H408.08C401.73 974.99 395.99 977.48 391.84 981.49L453.94 1031.2Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M515.82 981.71L453.94 1031.13 391.96 981.61"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M466.94 1022.14L517.41 1062.56"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M390.17 1062.33L440.8 1021.91"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
        <g>
          <path
            d="M478.3 797C514.75 797 544.3 767.45 544.3 731 544.3 694.55 514.75 665 478.3 665 441.85 665 412.3 694.55 412.3 731 412.3 767.45 441.85 797 478.3 797Z"
            fill="#32ffd4"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M496.3 749C513.2 732.1 518.8 710.3 508.8 700.3 507.3 698.8 505.5 697.6 503.6 696.8 501.9 696.1 500.1 695.7 498.3 695.5 496.8 695.3 495.3 696.1 494.7 697.4L486.7 712.9C485.8 714.7 486.5 716.8 488.2 717.7 488.3 717.8 488.4 717.8 488.5 717.8L492.9 719.6C494.7 720.3 495.5 722.3 494.8 724 494.7 724.2 494.6 724.4 494.5 724.5 488.8 733.6 481.1 741.3 472 747.1 470.4 748.1 468.3 747.6 467.3 746 467.2 745.8 467.1 745.6 467 745.5L465.2 741C464.5 739.2 462.4 738.3 460.6 739L460.3 739.1 444.8 747.1C443.5 747.7 442.7 749.1 442.9 750.6 443.1 752.5 443.5 754.3 444.2 756.1 445 758.1 446.2 759.8 447.7 761.3 457.6 771.5 479.4 765.9 496.3 749Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
        </g>
        <g>
          <path
            d="M1036.41 1106.59L905.72 1161.7C905.61 1161.7 905.61 1161.7 905.61 1161.59 903.77 1156.61 902.9 1151.31 903.01 1146.11L903.44 1144.7 1033.81 1089.91C1033.92 1089.91 1034.13 1090.89 1034.13 1090.89 1036.08 1100.63 1036.51 1105.83 1036.41 1106.59 1036.51 1106.59 1036.51 1106.59 1036.41 1106.59Z"
            fill="#30ff9b"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M1034.13 1090.78C1030.99 1073.78 1026.66 1056.78 1021.9 1040.11 1021.03 1037.29 1020.06 1034.04 1019.08 1031.01L1018.97 1030.9 966.89 1031.66 924.56 1047.79C924.45 1047.79 924.45 1047.9 924.45 1047.9 928.02 1054.94 931.06 1062.74 933 1070.32 934.63 1077.03 934.84 1082.66 933.22 1087.31 924.78 1111.03 903.99 1124.13 903.01 1146 903.01 1146.11 903.01 1146 903.01 1146L1034.13 1090.78Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M781.42 1161.81H639.47C639.36 1161.81 639.36 1161.81 639.36 1161.7 639.58 1156.5 640.99 1151.2 643.26 1146.43 643.26 1146.43 643.26 1146.32 643.37 1146.32L717.65 1140.15 785.43 1146.32C785.53 1146.32 785.53 1146.43 785.53 1146.43 783.37 1156.07 781.74 1161.16 781.42 1161.81 781.53 1161.81 781.53 1161.81 781.42 1161.81Z"
            fill="#30ff9b"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M701.63 1070.86C701.52 1070.86 701.52 1070.86 701.63 1070.86 701.63 1081.25 700.66 1089.91 698.28 1096.41 697.3 1099.23 695.79 1101.82 693.84 1104.1 676.95 1122.72 652.58 1126.51 643.27 1146.32H785.54C789.12 1129.76 792.15 1113.41 793.99 1096.73 794.21 1094.68 794.53 1092.19 794.75 1089.81 794.75 1089.81 794.75 1089.7 794.64 1089.7L750.36 1072.16 701.63 1070.86Z"
            fill="#d7efdf"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M668.81 346.72C666.86 351.05 673.69 356.79 674.34 357.33V357.44C670.44 369.78 668.27 384.72 673.69 393.27 680.18 403.99 693.72 407.03 709.42 404.97 709.53 404.97 709.53 404.97 709.53 405.08L708.33 412.11 767.67 422.73C767.78 422.73 767.78 422.73 767.78 422.62 768.53 419.48 777.95 414.06 778.5 410.6 779.04 407.78 779.36 404.75 779.9 401.72 782.94 384.72 782.61 365.56 784.78 352.78 788.78 328.63 792.57 310.77 764.64 292.69 748.07 281.97 727.93 280.34 709.53 285.11 709.53 285.11 684.62 289.98 683.54 319.86 683.54 319.97 678.45 340.76 678.02 342.06 678.02 342.06 678.02 342.17 677.91 342.17 677.37 342.39 670.22 343.25 668.81 346.72Z"
            fill="#faa378"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M708.55 412.11C710.07 411.79 722.41 408.87 731.5 397.93 731.61 397.82 731.5 397.71 731.4 397.71 722.09 402.59 710.61 404.75 709.74 404.97 709.74 404.97 709.63 404.97 709.63 405.08L708.44 412.01C708.33 412.01 708.44 412.11 708.55 412.11Z"
            fill="#faa37800"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M834.91 470.91C823 441.78 801.99 423.7 777.52 418.72 777.41 418.72 777.41 418.61 777.41 418.61L778.6 410.71C778.6 410.6 778.6 410.6 778.5 410.6L708.77 410.82C708.66 410.82 708.55 410.92 708.55 411.03L707.25 417.85C707.25 417.96 707.25 417.96 707.14 417.96 705.84 418.07 691.23 418.94 672.06 423.27 642.94 429.76 603.09 482.6 571.69 520.28H571.58L523.94 498.73H523.83L500.01 529.05 490.49 572.69V572.79C514.85 589.36 546.03 606.25 572.67 605.71 617.49 604.63 657.34 560.13 660.04 557.09 660.15 556.99 660.26 557.09 660.26 557.2L670.44 612.21C670.44 612.21 670.44 612.53 670.55 612.53L794.09 614.26 821.48 637.87C821.48 637.87 821.48 637.87 821.59 637.87 822.56 637.87 839.67 637.33 849.09 614.26 855.26 597.59 847.14 501.12 834.91 470.91Z"
            fill="#30ff9b"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M692.53 522.23L688.84 612.21C688.84 612.31 688.84 612.31 688.74 612.31H670.44C670.44 612.31 670.33 612.31 670.33 612.21L660.15 556.99V556.88L692.31 522.01C692.42 522.01 692.53 522.12 692.53 522.23Z"
            fill="#30ff9b00"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M683.65 319.97C696.86 336.11 719.05 351.91 720.35 352.89H720.46C721.76 351.81 745.8 332.53 758.36 344.33 775.03 359.28 751.54 381.58 744.28 385.37 744.17 385.37 744.17 385.48 744.28 385.59 759.22 401.07 778.5 401.72 779.9 401.72 779.9 401.72 780.01 401.72 780.01 401.61 783.04 385.59 786.94 364.8 788.78 353.86 792.79 329.72 794.2 303.84 766.37 285.76 749.8 275.04 726.63 274.61 708.33 279.37H708.23C707.47 278.4 695.67 263.89 678.99 272.87 664.48 282.4 670 303.3 683.65 319.97Z"
            fill="#000000"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M690.37 349.53C692.16 349.53 693.62 348.08 693.62 346.28 693.62 344.49 692.16 343.04 690.37 343.04 688.57 343.04 687.12 344.49 687.12 346.28 687.12 348.08 688.57 349.53 690.37 349.53Z"
            fill="#000000"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M404.62 459.32C404.73 459.32 404.84 459.21 404.73 459.11 398.78 455.64 397.15 443.08 404.62 445.35 430.18 453.04 459.74 468.53 482.37 479.79L482.47 479.89 473.49 516.71 453.78 542.59C453.78 542.59 453.78 542.59 453.67 542.59 440.79 543.02 420.65 537.93 406.14 526.78 406.14 526.78 406.14 526.78 406.03 526.78 384.48 525.04 362.51 522.12 361.53 516.82 360.34 510.43 386.11 509.78 393.9 509.67 394.01 509.67 394.01 509.45 393.9 509.45 385.78 506.53 340.42 502.42 353.95 484.33V484.23C348 475.13 356.33 470.47 359.04 470.26 359.15 470.26 359.15 470.15 359.15 470.15 358.61 469.07 355.14 462.35 359.91 458.02 363.15 455.21 397.37 458.24 404.62 459.32Z"
            fill="#faa378"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M482.37 479.89L453.78 542.59V542.69C466.02 553.63 476.73 563.48 490.38 572.58 490.49 572.58 490.49 572.58 490.49 572.58L523.83 498.73C523.83 498.63 523.83 498.63 523.83 498.63L482.58 479.79C482.37 479.79 482.37 479.79 482.37 479.89Z"
            fill="#8cffc7"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M793.98 612.21L807.95 549.3C807.95 549.19 808.16 549.19 808.16 549.3L810.44 629.85C810.44 629.96 810.33 629.96 810.22 629.96L793.98 612.21Z"
            fill="#30ff9b00"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M1006.31 992.68C1001.11 978.07 995.69 964.86 990.06 952.73 966.89 902.38 942.42 872.5 927.48 851.28 897.81 809.48 885.15 745.06 859.59 693.2 834.58 641.98 795.17 613.07 793.98 612.21 793.98 612.21 793.98 612.21 793.87 612.21H758.25L670.55 612.31C670.44 612.31 670.44 612.31 670.44 612.42 670.11 616.21 658.74 742.24 675.09 896.32 678.67 929.99 687.11 969.4 693.5 1005.24 697.94 1029.93 701.19 1052.67 701.51 1070.75 701.51 1070.75 701.51 1070.86 701.62 1070.86L758.36 1082.44 794.63 1089.59C794.74 1089.59 794.74 1089.59 794.74 1089.48 796.14 1076.38 797.44 1061.87 798.42 1044.98 798.96 1030.9 799.07 1017.69 798.85 1005.24 797.44 948.18 786.18 910.07 780.34 884.08 774.81 859.29 778.06 830.81 782.29 801.36 782.5 801.36 782.29 801.36 782.5 801.36 798.53 834.6 816.28 869.36 835.02 903.25 850.07 930.64 870.85 960.96 889.8 989.43 903.77 1010.66 916.22 1030.47 924.45 1047.69L924.56 1047.79 1018.97 1030.9C1019.08 1030.9 1019.08 1030.8 1019.08 1030.8 1015.51 1019.43 1011.39 1006.98 1006.31 992.68Z"
            fill="#009688"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M810.76 857.88C810.87 857.99 810.98 857.99 810.98 857.77L803.83 781.22C802.42 765.96 799.39 750.69 794.74 736.07L790.84 723.73C790.84 723.62 790.62 723.62 790.62 723.73 790.51 749.07 786.08 775.38 782.29 801.04 782.29 801.04 782.29 801.04 782.29 801.15 791.38 819.77 800.91 838.82 810.76 857.88Z"
            fill="#00968800"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M690.69 365.56C689.6 368.59 684.62 370.97 681.59 369.89"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M731.4 397.71C724.79 401.07 712.88 404.43 709.53 405.08"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M744.39 360.58L734.54 368.48"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M660.05 557.09L692.32 522.12"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M359.15 470.37C359.15 470.37 394.23 473.29 416.97 478.27"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M404.62 459.11L421.95 462.03"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M354.06 484.55C354.06 484.55 391.41 492.02 410.8 495.7"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M782.5 801.47C786.83 772.13 790.19 752.85 790.84 723.84"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M957.15 1074.76L933.98 1074.65"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M954.98 1092.84L932.57 1088.83"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M718.73 1105.29L698.26 1096.41"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M709.53 1121.1L690.04 1108"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M699.57 612.42C699.57 612.42 700.12 773.97 714.19 875.42 728.27 976.87 742.88 1051.91 740.07 1078.33"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M793.98 612.21L807.95 549.3"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
        <g>
          <path
            d="M281.75 893.86L264.45 919.77 295.96 906.91 281.75 893.86Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M281.75 893.86L264.45 919.77 295.96 906.91 281.75 893.86Z"
            fill="#ffffff00"
            opacity={0}
            strokeWidth={4}
            stroke="#ffffff00"
          />
          <path
            d="M281.75 893.86L300.01 934.18 359.68 863.25C359.81 863.12 359.68 862.93 359.49 863L281.75 893.86Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M246.51 868.65L281.75 893.86 359.55 863C359.62 862.93 359.62 862.8 359.49 862.8L246.51 868.65Z"
            fill="#ffffff"
            opacity={1}
            strokeWidth={4}
            stroke="#000000"
          />
          <path
            d="M281.75 893.86L289.4 910.77"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
          <path
            d="M359.49 863L281.75 893.86"
            stroke="#000000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={1}
            fill="none"
          />
        </g>
      </svg>
    </Box>
  );
}
