import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import { USER_SUBSCRIPTIONS, userSubscriptionsTypes } from 'constants/userSubscriptionsTypes';

import { useAuth } from 'contexts/FirebaseContext';
import moment from 'moment-timezone';

const defaultOptions = {
  enabled: true,
};

const useUserUsage = ({ enabled } = defaultOptions) => {
  const { user } = useAuth();
  const [userUsage, setUserUsage] = useState(null);
  const currentSubscription = USER_SUBSCRIPTIONS[user.subscription?.type] ?? USER_SUBSCRIPTIONS.free;

  useEffect(() => {
    if (enabled && currentSubscription.id === userSubscriptionsTypes.PREMIUM) {
      const db = getDatabase();
      const userUsageRef = ref(db, `usage/${user.id}`);

      onValue(userUsageRef, (snapshot) => {
        const data = snapshot.val();
        setUserUsage(data);
      });

      return () => {
        off(userUsageRef);
      };
    }

    return () => {};
  }, [currentSubscription.id, enabled, user.id]);

  let response = {};

  if (currentSubscription.limits) {
    Object.keys(currentSubscription.limits).forEach((key) => {
      const currentMonth = moment().format('YYYY-MM');

      const limit = currentSubscription.limits[key];
      const usage = userUsage?.[key]?.[currentMonth] ?? 0;
      const usagePercentage = (usage / limit) * 100;
      const remaining = limit - usage;
      const remainingPercentage = (remaining / limit) * 100;

      response = {
        ...response,
        [key]: {
          limit,
          usage,
          remaining,
          remainingPercentage,
          usagePercentage,
        },
      };
    });
  }

  return response;
};

export default useUserUsage;
