import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
// hooks
import { useAuth } from '../contexts/FirebaseContext';
// routes
import { PATH_APPLICATION } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const waitRedirect = searchParams.get('waitRedirect');

  if (isAuthenticated && !waitRedirect) {
    return <Navigate to={PATH_APPLICATION.dashboard} />;
  }

  return <>{children}</>;
}
