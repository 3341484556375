import { useEffect, useReducer } from 'react';
import { getDatabase, ref, child, get } from 'firebase/database';
import _isObject from 'lodash/isObject';

import logger from '../utils/logger';

const defaultOptions = {
  enabled: true,
};

const useReadOnceList = (keyPaths, options = {}) => {
  const mergedOptions = { ...defaultOptions, ...options };

  const initialState = {
    data: undefined,
    isLoading: true,
    isError: false,
    error: null,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, isLoading: true };
      case 'FETCHED':
        return { ...initialState, isLoading: false, isError: false, data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, isLoading: false, isError: true, error: action.payload };
      case 'DISABLED':
        return { ...initialState, isLoading: false, isError: false, error: null };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;

    if (!keyPaths) {
      logger.captureException(new Error('useReadOnceList: empty or undefined keypaths'));
      dispatch({ type: 'FETCH_ERROR', payload: 'empty or undefined keypaths' });
      return;
    }

    const dbRef = ref(getDatabase());

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });

      try {
        const snapshots = await Promise.all(keyPaths.map((k) => get(child(dbRef, k))));
        const data = snapshots.map((snapshot) => {
          if (!snapshot.exists()) {
            return null;
          }

          const snapshotValue = snapshot.val();
          const data = _isObject(snapshotValue)
            ? {
                ...snapshotValue,
                id: snapshot.key,
              }
            : snapshotValue;

          return data;
        });

        if (cancelRequest) return;

        dispatch({ type: 'FETCHED', payload: data });
      } catch (error) {
        if (cancelRequest) return;
        logger.captureException(error);
        dispatch({ type: 'FETCH_ERROR', payload: error.message });
      }
    };

    if (!mergedOptions.enabled) {
      dispatch({ type: 'DISABLED', payload: 'disabled query' });
      return;
    }

    fetchData();

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      cancelRequest = true;
    };
    // eslint-disable-next-line
  }, [keyPaths.join(), mergedOptions.enabled]);

  return state;
};

export default useReadOnceList;
