import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import useLocalStorage from 'hooks/useLocalStorage';
import MaintenancePage from 'pages/MaintenancePage';

// ----------------------------------------------------------------------

MaintenanceModeGuard.propTypes = {
  children: PropTypes.node,
};


export default function MaintenanceModeGuard({ children }) {
  const [bypassMaintenance] = useLocalStorage('bypassMaintenance');
  const [isMaintenanceEnabled, setMaintenanceEnabled] = useState(false);
  
  useEffect(() => {
    const db = getDatabase();
    const systemMaintenanceRef = ref(db, 'system/appMaintenanceMode');

    onValue(systemMaintenanceRef, (snapshot) => {
      const data = snapshot.val();
      setMaintenanceEnabled(data);
    });

    return () => {
      off(systemMaintenanceRef);
    };
  }, []);

  if (isMaintenanceEnabled && !bypassMaintenance) return <MaintenancePage />;

  return <>{children}</>;
}
