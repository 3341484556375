import React from 'react';
import Lottie from 'lottie-react';
import { Box, Typography } from '@mui/material';
import accountInitAnimation from '../assets/lottie/processing.json'; // You'll need to provide this animation file

const AccountInitializationScreen = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box sx={{ width: '300px', height: '300px' }}>
        <Lottie animationData={accountInitAnimation} loop />
      </Box>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Stiamo preparando il tuo account...
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Ci vorrà solo un momento.
      </Typography>
    </Box>
  );
};

export default AccountInitializationScreen;