import { useEffect } from 'react';

export const useAnchorLink = () => {
  useEffect(() => {
    requestAnimationFrame(() => {
      const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }, []);
};
