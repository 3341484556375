import { styled } from '@mui/material/styles';
// @mui
import { Box, Typography, Container, Link } from '@mui/material';
import Logo from 'components/Logo';
// components
import Page from '../components/Page';
import { MaintenanceIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function MaintenancePage() {
  return (
    <Page title="App in manutenzione" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 580, margin: 'auto', textAlign: 'center' }}>
            <Box sx={{ display: 'inline-block', mb: 4 }}>
              <Logo />
            </Box>
            <Typography variant="h3" paragraph>
              Manutenzione in corso
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Ci scusiamo per il disagio. Tra pochissimo saremo di nuovo online.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Per qualsiasi necessità contattaci a{' '}
              <Link href="mailto:support@pazientiapp.it">support@pazientiapp.it</Link>
            </Typography>

            <MaintenanceIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

MaintenancePage.propTypes = {};
