import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';

import { useTenantPublicData } from 'hooks/useTenant';

import LogoPazientiSvg from './pazienti-logo.svg';
import LogoIconPazientiSvg from './pazienti-logo-icon.svg';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  onlySymbol: PropTypes.bool,
  forceDefault: PropTypes.bool,
};

export default function Logo({ disabledLink = false, onlySymbol = false, forceDefault = false, sx }) {
  const { data, isLoading } = useTenantPublicData();

  if (isLoading) {
    return <Skeleton width={onlySymbol ? 40 : 130} height={73} />;
  }

  const logo =
    !forceDefault && data?.logo ? (
      <Box sx={{ width: onlySymbol ? 40 : 130, ...sx }}>
        <img src={onlySymbol ? data?.logoSymbol : data?.logo} alt={`${data?.name} logo`} style={{ maxWidth: '100%' }} />
      </Box>
    ) : (
      <Box sx={{ width: onlySymbol ? 40 : 130, ...sx }}>
        <img
          src={onlySymbol ? LogoIconPazientiSvg : LogoPazientiSvg}
          alt="Patient Manager logo"
          style={{ maxWidth: '100%' }}
        />
      </Box>
    );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
