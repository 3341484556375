class APIException extends Error {
  code;

  message;

  constructor({ code, message, errors }) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.message = message;
    this.errors = errors;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(this.message).stack;
    }
  }
}

const exceptionHandler = async (response) => {
  if (!response.ok) {
    const exception = await response.json();
    throw new APIException(exception);
  }

  return response;
};

const responseHandler = async (response) => response.json();

export const defaultHeaders = {
  'Content-Type': 'application/json',
};

/**
 * GET API fetcher
 * @param resource '/path'
 * @returns APIResponseSuccess or throw an APIError with APIExceptionResponse
 */
function get(resource, headers) {
  return fetch(resource, {
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  })
    .then(exceptionHandler)
    .then(responseHandler);
}

/**
 * POST API fetcher
 * @param resource '/path'
 * @param body body to be pass as request payload
 * @returns APIResponseSuccess or throw an APIError with APIExceptionResponse
 */
function post(resource, body, headers) {
  return fetch(resource, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    // credentials: 'include',
  })
    .then(exceptionHandler)
    .then(responseHandler);
}

/**
 * PATCH API fetcher
 * @param resource '/path'
 * @param body body to be pass as request payload
 * @returns APIResponseSuccess or throw an APIError with APIExceptionResponse
 */
function patch(resource, body, headers) {
  return fetch(resource, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  })
    .then(exceptionHandler)
    .then(responseHandler);
}

/**
 * POST API fetcher
 * @param resource '/path'
 * @param body body to be pass as request payload
 * @returns APIResponseSuccess or throw an APIError with APIExceptionResponse
 */
function multipartPost(resource, data) {
  return fetch(resource, {
    method: 'POST',
    body: data,
    credentials: 'include',
  })
    .then(exceptionHandler)
    .then(responseHandler);
}

/**
 * DELETE API fetcher
 * @param resource '/path'
 * @returns APIResponseSuccess or throw an APIError with APIExceptionResponse
 */
function deleteMethod(resource, headers) {
  return fetch(resource, {
    method: 'DELETE',
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  })
    .then(exceptionHandler)
    .then(responseHandler);
}

const httpClient = {
  get,
  post,
  patch,
  delete: deleteMethod,
  multipartPost,
};

export default httpClient;
