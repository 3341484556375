import { useEffect, useReducer, useCallback, useRef } from 'react';
import { getDatabase, ref, child, get } from 'firebase/database';
import _isObject from 'lodash/isObject';

import logger from '../utils/logger';

export const readOnce = (keyPath) => {
  const dbRef = ref(getDatabase());

  return new Promise((res, rej) => {
    get(child(dbRef, keyPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          res({
            ...snapshot.val(),
            id: snapshot.key,
          });
        } else {
          res(null);
        }
      })
      .catch((error) => {
        rej(error);
      });
  });
};

const defaultOptions = {
  enabled: true,
};

const useReadOnce = (keyPath, options = {}) => {
  const mergedOptions = { ...defaultOptions, ...options };

  const initialState = {
    data: null,
    isLoading: true,
    isError: false,
    error: null,
  };

  const cancelRequest = useRef(false);

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, isLoading: true };
      case 'FETCHED':
        return { ...initialState, isLoading: false, isError: false, data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, isLoading: false, isError: true, error: action.payload };
      case 'DISABLED':
        return { ...initialState, isLoading: false, isError: false, error: null };
      default:
        return state;
    }
  }, initialState);

  const fetchData = useCallback(async () => {
    dispatch({ type: 'FETCHING' });

    try {
      const dbRef = ref(getDatabase());

      const snapshot = await get(child(dbRef, keyPath));
      const snapshotValue = snapshot.val();
      const data = _isObject(snapshotValue)
        ? {
            ...snapshotValue,
            id: snapshot.key,
          }
        : snapshotValue;

      if (cancelRequest.current) return;
      dispatch({ type: 'FETCHED', payload: snapshot.exists() ? data : null });
    } catch (error) {
      if (cancelRequest.current) return;
      logger.captureException(error);
      dispatch({ type: 'FETCH_ERROR', payload: error.message });
    }
  }, [keyPath]);

  useEffect(() => {
    cancelRequest.current = false;
    if (!keyPath) {
      logger.captureException(new Error('useReadOnce: empty or undefined keypath'));
      dispatch({ type: 'FETCH_ERROR', payload: 'empty or undefined keypath' });
      return;
    }

    if (!mergedOptions.enabled) {
      dispatch({ type: 'DISABLED', payload: 'disabled query' });
      return;
    }

    fetchData();

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      cancelRequest.current = true;
    };
  }, [keyPath, fetchData, mergedOptions.enabled]);

  return {
    ...state,
    refetch: fetchData,
    readOnce,
  };
};

export default useReadOnce;
