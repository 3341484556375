import { createContext, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { getAll, getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';

import { firebaseApp } from './FirebaseContext';

const RemoteConfigContext = createContext({});
const firebaseRemoteConfig = getRemoteConfig(firebaseApp);

// Only for dev
// firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 3600000;

firebaseRemoteConfig.defaultConfig = {
  calendarEnabled: false,
};

const RemoteConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(() => getAll(firebaseRemoteConfig));

  useEffect(() => {
    const loadConfig = async () => {
      await fetchAndActivate(firebaseRemoteConfig);
      setConfig(getAll(firebaseRemoteConfig));
    };

    loadConfig();
  }, []);

  return <RemoteConfigContext.Provider value={config}>{children}</RemoteConfigContext.Provider>;
};

RemoteConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useRemoteConfig = () => {
  const context = useContext(RemoteConfigContext);
  return context;
};

export { RemoteConfigProvider, useRemoteConfig };
